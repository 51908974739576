'use strict';

Gri.module({
  name: 'tab-beta',
  ieVersion: null,
  $el: $('.tab-beta'),
  container: '.tab-beta',
  fn: function () {

    // region variables
    const $this = this.$el;
    let $tabContainer = $this.find('.nav-tabs');
    // endregion

    // region run
    resizeController();
    // endregion

    // region events
    $(window).on('resize', resizeController);
    // endregion

    // region events functions

    // endregion

    // region functions
    function resizeController() {
      let windowW = $(window).width();

      if(windowW <= 767) {
        $tabContainer.find('li').width('');

        let heights = [];
        $tabContainer.find('li').each(function () {
          let thisH = $(this).height();
          heights.push(thisH);
        });
        $tabContainer.find('li').height(Math.max.apply(undefined, heights));
      } else {
        $tabContainer.find('li').height('');
        $tabContainer.find('li').width(100 / $tabContainer.find('li').length + '%');
      }
    }
    // endregion

  }
});
